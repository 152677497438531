import * as React from "react";
import { graphql } from "gatsby";
import { useIntl } from "react-intl";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Hero from "../components/hero";
import Title from "../components/title";
import Block from "../components/block";
import Articles from "../components/articles";

const ArticleTemplate = ({ data }) => {
  const { formatMessage } = useIntl();
  const { title, seo, featuredImage, blocks } = data.article;
  const seoTitle = seo?.title || title;
  const seoDescription = seo?.description?.description;
  const seoImage = seo?.image || featuredImage;
  const articles = data.articles.nodes;
  const canonicalURL = seo?.canonicalURL;

  return (
    <Layout>
      <Seo
        title={seoTitle}
        description={seoDescription}
        image={seoImage}
        canonicalURL={canonicalURL}
      />
      <Hero title="Journal" pageTitle={false} />
      <Title title={title} />
      {blocks?.map((block) => (
        <Block block={block} key={block.contentful_id} />
      ))}
      <Articles
        articles={articles}
        heading={formatMessage({ id: "journalRelated" })}
      />
    </Layout>
  );
};

export default ArticleTemplate;

export const query = graphql`
  query ($id: String!, $locale: String!) {
    article: contentfulArticle(
      contentful_id: { eq: $id }
      node_locale: { eq: $locale }
    ) {
      title
      seo {
        canonicalURL
        title
        description {
          description
        }
        image {
          description
          title
          gatsbyImageData(
            width: 1200
            height: 1200
            quality: 80
            resizingBehavior: FILL
          )
        }
      }
      featuredImage {
        description
        title
        gatsbyImageData(
          width: 1200
          height: 1200
          quality: 80
          resizingBehavior: FILL
        )
      }
      blocks {
        ... on ContentfulRichText {
          contentful_id
          __typename
          content {
            raw
            references {
              ... on ContentfulAsset {
                contentful_id
                __typename
                description
                gatsbyImageData(layout: FULL_WIDTH)
              }
              ... on ContentfulLink {
                id
                button
                buttonBackgroundColour
                link
                node_locale
                textColour
                target
                title
              }
            }
          }
        }
        ... on ContentfulImageWithText {
          contentful_id
          __typename
          image {
            description
            title
            gatsbyImageData(layout: FULL_WIDTH)
          }
          mobileImage {
            gatsbyImageData(quality: 80)
          }
          imageAlt
          text {
            raw
          }
        }
        ... on ContentfulSlideshow {
          contentful_id
          __typename
          slides {
            contentful_id
            image {
              description
              title
              gatsbyImageData(quality: 80)
            }
            mobileImage {
              gatsbyImageData(quality: 80)
            }
            imageAlt
            text {
              raw
            }
          }
          layout
          backgroundColor
          padding
        }
      }
    }

    articles: allContentfulArticle(
      filter: { id: { ne: $id }, node_locale: { eq: $locale } }
      sort: { fields: publishedAt, order: DESC }
      limit: 2
    ) {
      nodes {
        contentful_id
        publishedAt
        title
        slug
        featuredImage {
          description
          gatsbyImageData(layout: FULL_WIDTH)
          title
        }
        publishedAt
        excerpt {
          excerpt
        }
      }
    }

    translations: contentfulTranslations(node_locale: { eq: $locale }) {
      translations {
        key
        value
      }
    }
  }
`;
