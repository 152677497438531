import * as React from "react";

const Title = ({ title }) => (
  <div className="title">
    <div className="container">
      <h1>{title}</h1>
    </div>
  </div>
);

export default Title;
