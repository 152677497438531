import * as React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { FormattedDate } from "react-intl";
import SwiperCore, { A11y, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import Link from "../components/link";

SwiperCore.use([A11y, Navigation]);

const ArticleTitle = ({ sectionHeading, children, ...props }) => {
  if (sectionHeading) {
    return <h3 {...props}>{children}</h3>;
  }

  return <h2 {...props}>{children}</h2>;
};

const Articles = ({ articles, heading }) => {
  if (articles) {
    return (
      <section
        className="articles"
        aria-labelledby={heading ? "articles-title" : null}
      >
        <div className="container">
          {heading && (
            <h2 id="articles-title" className="heading">
              Journal
            </h2>
          )}
          <Swiper slidesPerView="auto">
            {articles.map((article) => (
              <SwiperSlide key={article.contentful_id}>
                <Link href={`/journal/${article.slug}/`}>
                  <span className="visually-hidden">{article.title}</span>
                </Link>
                <div>
                  <time dateTime={article.publishedAt}>
                    <FormattedDate
                      value={article.publishedAt}
                      day="numeric"
                      month="long"
                      year="numeric"
                    />
                  </time>
                  <ArticleTitle
                    className="h2"
                    aria-hidden
                    sectionHeading={heading}
                  >
                    {article.title}
                  </ArticleTitle>
                  {article.excerpt && <p>{article.excerpt.excerpt}</p>}
                </div>
                {article.featuredImage && (
                  <GatsbyImage
                    image={article.featuredImage.gatsbyImageData}
                    alt={article.featuredImage.title}
                  />
                )}
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </section>
    );
  }

  return null;
};

export default Articles;
